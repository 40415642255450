import { Typography } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import { Translate } from "react-localize-redux";

import { Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  link: {
    color: "#00BCD4",
    marginTop: "5px",
    cursor: "pointer"
  },
}));

type DriverIdentificationConditionalLinkProps = {
  pinLength: number, 
  pin: string[],
  pathname: string
}

const DriverIdentificationConditionalLink: React.FC<DriverIdentificationConditionalLinkProps> = ({ pinLength, pin, pathname }) => {
  const history = useHistory();
  const classes = useStyles();

  const navigateToForgotPinPage = () => {
    history.push(`/${pathname}/forgotPin`);
  };

  return (
    <Typography
      variant="subtitle2"
      className={classes.link}
      component={"span"}
      onClick={!pin[pinLength - 1] ? navigateToForgotPinPage : undefined}
    >
      <Translate id="driverIdentification.forgotPin" />
    </Typography>
  );
};

export default DriverIdentificationConditionalLink;
