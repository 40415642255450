import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Select} from "antd";
import {Translate} from "react-localize-redux";

import CustomSelect from "../Select";
import { requestChargeStations, requestChargeStationById } from "../../../../actions/qualityCharging/actionChargeStations";

const {Option} = Select;

const ChargeStationSelect = ({ hasEvoConnectionStarted, chargeStationId, setChargeStationId, setConnectorNumber }) => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState();
    const chargeStations = useSelector((state) => state.chargeStations.entities);

    useEffect(() => {
        dispatch(requestChargeStations({searchTerm}));
      }, [hasEvoConnectionStarted, searchTerm]);

    useEffect(() => {
        if (chargeStationId) dispatch(requestChargeStationById(chargeStationId));
        setConnectorNumber(null);
    }, [chargeStationId]);

    return (
        <CustomSelect value={chargeStationId}
                      placeholder={<Translate id="common.select.chargeStations"/>}
                      handleChange={setChargeStationId}
                      handleSearch={setSearchTerm}
                      allowClear={false}
                      >
            {chargeStations.map((chargeStation, idx) => (
                <Option key={`${chargeStation.id}-${idx}`} value={chargeStation.id}>{chargeStation.id}</Option>
            ))}
        </CustomSelect>
    );

};

export default ChargeStationSelect;
