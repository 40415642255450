import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Input, Switch } from "antd";
import { Translate } from "react-localize-redux";
import Label from "../../common/views/Label";
import TreeSelect from "../../common/selects/specificSelects/TreeSelect";
import RoleSelect from "../../common/selects/specificSelects/RoleSelect";
import FormModal from "../../common/modals/FormModal";
import { requestRoles, requestUpdateUser } from "../../../actions/actionUser";
import { requestCostcenters } from "../../../actions/actionCostcenter";
import {
  getGroupValuesFromIds,
  getIdsFromGroupValues,
} from "../../../managers/groupManager";
import FormBuilder from "../../../managers/formBuilder";
import { getRoleId, roles } from "../../../models/Role";
import { packages } from "../../../models/Package";
import {
  allowedRoles,
  isAuthorized,
  isViewer,
} from "../../../managers/authManager";
import CostCenterSelect from "../../common/selects/specificSelects/CostCenterSelect";
import { useInputChangeHandler } from "../../../hooks/useInputChangeHandler";
import { getTranslation } from "../../../translations/translations";
import "./inputStyle.css";

const getDefaultInputValue = (user) => ({
  foreignId: user ? user.foreignId : null,
  firstName: user ? user.firstName : null,
  lastName: user ? user.lastName : null,
  email: user ? user.email : null,
  groupValue: user ? user.groups : null,
  roleId: user ? user.role?.id : null,
  costcenterId: user ? user.costcenterId : null,
  canCalibrate: user ? user.canCalibrate : null,
  mileageInKm: user ? Math.round(user.mileageInMeters / 1000) : null,
  description: user ? user.description : null,
});

const UserModal = (props) => {
  const { onClose, user, tree, loading, archive, costcenters } = props;

  function addZeroValues(number) {
    if(number){
      return number.toString().padStart(4, '0');
    } else {
      return null
    }
  }

  const [pin, setPin] = useState(user ? addZeroValues(user.driverIdentificationPin) : null);
  const [isPinUnValidation, setIsPinUnValid] = useState(false); 
  
  const { archiveComment } = user;
  const userRoles = useSelector((state) => state.users.roles);
  const me = useSelector((state) => state.users.me);
  const driver = getRoleId(userRoles, roles.Driver);
  const driverId = driver ? driver.id : undefined;
  const { handleInputChange, fields, setFields } = useInputChangeHandler(
    getDefaultInputValue(user)
  );

  const {
    foreignId,
    firstName,
    lastName,
    email,
    groupValue,
    roleId,
    description,
    canCalibrate,
    costcenterId,
  } = fields;

  useEffect(() => {
    props.requestRoles();
    props.requestCostcenters();
  }, []);

  useEffect(() => {
    setFields({
      ...user,
      roleId: user.role?.id,
      groupValue:
        user?.groups &&
        getGroupValuesFromIds(
          tree,
          user.groups.map((g) => g.id)
        ),
    });
  }, [user]);

  const handlePinChange = (value) => {
    if(value.length <= 4) {
      setPin(value)
    }
  } 

  function handleOnSave() {
    const groupIds = getIdsFromGroupValues(tree, fields.groupValue);
    const isPinInpudValueUnvalid =  pin.length >= 1 && pin.length <= 3;
      
    if (isPinInpudValueUnvalid) {
      setIsPinUnValid(true);
      return;
    }
      props.requestUpdateUser({ ...fields, groupIds, id: user.id, driverIdentificationPin: pin });
      onClose();
  }

  const inputs = [
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.foreignKey" />,
      (
        <Input
          value={foreignId}
          onChange={(e) => handleInputChange("foreignId", e.target.value)}
        />
      )
    )
      .withValue(foreignId)
      .withKey("foreignId")
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.firstname" />,
      (
        <Input
          value={firstName}
          onChange={(e) => handleInputChange("firstName", e.target.value)}
        />
      )
    )
      .withValue(firstName)
      .withKey("firstName")
      .withValidationMessage(
        <Translate id="user.editmodal.firstnameValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.lastname" />,
      (
        <Input
          value={lastName}
          onChange={(e) => handleInputChange("lastName", e.target.value)}
        />
      )
    )
      .withValue(lastName)
      .withKey("lastName")
      .withValidationMessage(
        <Translate id="user.editmodal.lastnameValidation" />
      )
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.email" />,
      (
        <Input
          value={email}
          onChange={(e) => handleInputChange("email", e.target.value)}
        />
      )
    )
      .withValue(email)
      .withKey("Email")
      .withType("email")
      .withValidationMessage(<Translate id="user.editmodal.emailValidation" />)
      .isRequired()
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.groups" />,
      (
        <TreeSelect
          value={groupValue}
          onChange={(value) => handleInputChange("groupValue", value)}
        />
      )
    )
      .withValue(groupValue)
      .withKey("groupValue")
      .build(),
  ];
  // HW only allows to edit role for Admin
  if (
    (me.isHWNOE && isAuthorized(me, allowedRoles(roles.Admin))) ||
    !me.isHWNOE
  )
    inputs.push(
      new FormBuilder.FormInput(
        <Translate id="user.editmodal.role" />,
        (
          <RoleSelect
            allowClear={false}
            onChange={(value) => handleInputChange("roleId", value)}
            value={roleId}
          />
        )
      )
        .withValue(roleId)
        .withKey("roleId")
        .withValidationMessage(
          <Translate id="user.editmodal.rolesValidation" />
        )
        .isRequired()
        .build()
    );

  if (isAuthorized(me, allowedRoles(roles.Admin), [packages.CostCenter]))
    inputs.push(
      new FormBuilder.FormInput(
        <Translate id="user.editmodal.costCenter" />,
        (
          <CostCenterSelect
            value={costcenterId}
            onChange={(value) => handleInputChange("costcenterId", value)}
            allowClear={true}
            showArchived={false}
          />
        )
      )
        .withValue(costcenterId)
        .withKey("costcenterId")
        .build()
    );

  if (
    isAuthorized(me, allowedRoles(roles.Admin), [packages.DriverIdentification])
  ) {
    inputs.push(
      new FormBuilder.FormInput(
        <Translate id="user.editmodal.pin" />,
        (
          <>
          <Input
            value={pin}
            onChange={(e) => handlePinChange(e.target.value)}
            min="0"
            max="9999"
            placeholder={getTranslation('driverIdentification.pinPlaceholder')}
            type="number"
          />
          {isPinUnValidation && <p style={{color: "red"}}><Translate id="driverIdentification.pinValidationMessage" /></p> } 
          </>
        )
      )
        .withValue(pin)
        .withKey("pin")
        .build()
    );
  }

  if (driverId && driverId === roleId)
    inputs.push(
      new FormBuilder.FormInput(
        <Translate id="user.editmodal.allowCalibrate" />,
        (
          <Switch
            checked={canCalibrate}
            onChange={(value) => handleInputChange("canCalibrate", value)}
          />
        )
      )
        .withValue(canCalibrate)
        .withKey("canCalibrate")
        .withValuePropName("checked")
        .build()
    );

  inputs.push(
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.description" />,
      (
        <Input.TextArea
          value={description}
          onChange={(e) => handleInputChange("description", e.target.value)}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      )
    )
      .withValue(description)
      .withKey("description")
      .build()
  );

  const archiveInputs = [
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.foreignKey" />,
      <Label />
    )
      .withValue(foreignId)
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.firstname" />,
      <Label />
    )
      .withValue(firstName)
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.lastname" />,
      <Label />
    )
      .withValue(lastName)
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.email" />,
      <Label />
    )
      .withValue(email)
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.groups" />,
      <TreeSelect value={groupValue} disabled />
    )
      .withValue(groupValue)
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.role" />,
      <RoleSelect disabled />
    )
      .withValue(user.roles && user.roles.map((r) => r.id))
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.description" />,
      <Label autoSize={{ minRows: 3, maxRows: 5 }} />
    )
      .withValue(description)
      .build(),
    new FormBuilder.FormInput(
      <Translate id="user.editmodal.archiveComment" />,
      <Label />
    )
      .withValue(archiveComment)
      .build(),
  ];

  const title = (
    <>
      <Translate id="user.editmodal.title" />
      <i>
        {firstName} {lastName}
      </i>
    </>
  );

  return (
    <FormModal
      loading={loading}
      action={archive ? "ok" : "update"}
      onSave={handleOnSave}
      onClose={onClose}
      title={title}
      inputs={archive ? archiveInputs : inputs}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestUpdateUser,
      requestRoles,
      requestCostcenters,
    },
    dispatch
  );
}

function mapStateToProp(state) {
  return {
    roles: state.users.roles,
    costcenters: state.users.costcenters,
    loading: state.users.loading,
  };
}

// UserModal.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
//   archive: PropTypes.bool,
// };

export default connect(mapStateToProp, mapDispatchToProps)(UserModal);
