import { takeLatest, put, call } from "typed-redux-saga/macro";
import {
  receiveVehicleNameById,
  receiveDriverNameByPin,
  receiveDriversByName,
  receiveAssignVehicleToDriverByDriverId,
  receiveAssignVehicleToDriverByPin,
  assignVehicleToDriverByPinFailed,
  assignVehicleToDriverByDriverIdFailed,
  getDriverNameByPinFailed,
  getDriversByNameFailed,
  getVehicleNameByIdFailed,
  DRIVER_IDENTIFICATION_TYPES,
} from "../actions/actionDriverIdentification";

import {
  getVehicleNameByIdAsync,
  getDriverNameByPinAsync,
  getDriversByNameAsync,
  assignVehicleToDriverByPinAsync,
  assignVehicleToDriverByDriverIdAsync,
  getVehicleQrCodeById,
} from "./api";

import {
  RequestVehicleNameById,
  RequestDriverNameByPin,
  RequestDriversByName,
  RequestAssignVehicleToDriverByDriverId,
  RequestAssignVehicleToDriverByPin,
  RequestQrCodeByVehicleId
} from "../types/actions/actionDriverIdentification";

import filedownload from "js-file-download";
import { handleError } from "../managers/sagaManager";

function downloadFile(response: { data: string | ArrayBuffer | ArrayBufferView | Blob; }, filename: string) {
  filedownload(response.data, filename);
}

function* getVehicleNameById(action: RequestVehicleNameById) {
  try {
    const response = yield* call(getVehicleNameByIdAsync, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveVehicleNameById(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      // Handle 404 specifically
    yield* put(getVehicleNameByIdFailed({message: "Fahrzeug nicht gefunden"}));
    } else if(error.response && error.response.status === 403) {
      yield* put(getVehicleNameByIdFailed({message: "Die Modul-Fahrzeugidentifikation ist nicht aktiviert"}));
    } else {
      yield* put(getVehicleNameByIdFailed(error));
    }
  }
}

function* getDriverNameByPin(action: RequestDriverNameByPin) {
  try {
    const response = yield* call(getDriverNameByPinAsync, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDriverNameByPin(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      // Handle 404 specifically
      yield* put(getDriverNameByPinFailed({
        message: "Pin ist falsch"
      }));
    } else {
      yield* put(getDriverNameByPinFailed(error));
    }
  }
}

function* getDriversByName(action: RequestDriversByName) {
  try {
    const response = yield* call(getDriversByNameAsync, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveDriversByName(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    yield* put(getDriversByNameFailed(error));
  }
}

function* assignVehicleToDriverByPin(action: RequestAssignVehicleToDriverByPin) {
  try {
    const response = yield* call(assignVehicleToDriverByPinAsync, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAssignVehicleToDriverByPin(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    yield* put(assignVehicleToDriverByPinFailed(error));
  }
}

function* assignVehicleToDriverByDriverId(action: RequestAssignVehicleToDriverByDriverId) {
  try {
    const response = yield* call(assignVehicleToDriverByDriverIdAsync, action.data);
    if (response.status >= 200 && response.status < 300) {
      yield* put(receiveAssignVehicleToDriverByDriverId(response.data));
    } else {
      throw response;
    }
  } catch (error: any) {
    yield* put(assignVehicleToDriverByDriverIdFailed(error));
  }
}

function* requestQrCodeByVehicleId (action: RequestQrCodeByVehicleId) {
  try {
    const response = yield* call(getVehicleQrCodeById, action.data);
    const imageName = response.headers['x-download-file-name'];

    if (response.status >= 200 && response.status < 300) {
      downloadFile(response,`${imageName}`);
    } else {
      throw response;
    }
  } catch (error: any) {
    yield put(handleError(error));
  }
}

export default function* sagaDriverIdentification() {
  yield* takeLatest(
    DRIVER_IDENTIFICATION_TYPES.REQUEST_VEHICLE_NAME_BY_ID,
    getVehicleNameById
  );

  yield* takeLatest(
    DRIVER_IDENTIFICATION_TYPES.REQUEST_DRIVER_NAME_BY_PIN,
    getDriverNameByPin
  );

  yield* takeLatest(
    DRIVER_IDENTIFICATION_TYPES.REQUEST_DRIVERS_BY_NAME,
    getDriversByName
  );

  yield* takeLatest(
    DRIVER_IDENTIFICATION_TYPES.REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_PIN,
    assignVehicleToDriverByPin
  );

  yield* takeLatest(
    DRIVER_IDENTIFICATION_TYPES.REQUEST_ASSIGN_VEHICLE_TO_DRIVER_BY_DRIVER_ID,
    assignVehicleToDriverByDriverId
  );

  yield* takeLatest(
    DRIVER_IDENTIFICATION_TYPES.REQUEST_VEHICLE_QR_CODE,
    requestQrCodeByVehicleId
  );
}
