import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Select} from "antd";
import {Translate} from "react-localize-redux";

import { requestDriversByName } from "../../actions/actionDriverIdentification";

import CustomSelect from "../common/selects/Select";
import { UserResponseInfo } from "../../types/user/userResponse.types";
import { RootState } from "../../store";

const {Option} = Select;

type DriverSearchBoxProps = {
    selectedDriver: UserResponseInfo | null,
    vehicleId: string,
    setSelectedDriver: (driver: UserResponseInfo) => void,
    openAssignModal: () => void,
}

const DriverSearchBox: React.FC<DriverSearchBoxProps> = ({ selectedDriver, vehicleId, setSelectedDriver, openAssignModal }) => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const drivers = useSelector((state: RootState) => state.driverIdentification.drivers);

    useEffect(() => {
       if(searchTerm.length > 2) dispatch(requestDriversByName({searchTerm, vehicleId}));
      }, [searchTerm, vehicleId]);

    const handleChange = (value: string) => {
        openAssignModal();
        const newSelectedDriver = drivers.find((driver: UserResponseInfo) => driver.id === value);
        newSelectedDriver && setSelectedDriver(newSelectedDriver);
    }

    return (
        <CustomSelect value={selectedDriver?.id}
                      placeholder={<Translate id="driverIdentification.searchName" />}
                      handleChange={handleChange}
                      handleSearch={setSearchTerm}
                      allowClear={false}
                      >
            {drivers.map((driver, idx) => (
                <Option key={`${driver.id}-${idx}`} value={driver.id}>{driver.firstName} {driver.lastName}</Option>
            ))}
        </CustomSelect>
    );

};

export default DriverSearchBox;
