import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
  requestVehicleNameById,
  requestAssignVehicleToDriverByDriverId,
} from "../../actions/actionDriverIdentification";

import DriverIdentification from "./DriverIdentification";
import DriverSearchBox from "./DriverSearchBox";
import DriverIdentificationAssignModal from "./DriverIdentificationAssignModal/DriverIdentificationAssignModal";

import { Translate } from "react-localize-redux";

import { makeStyles } from "@material-ui/core";

import { Theme } from "@material-ui/core";
import { RootState } from "../../store";
import { UserResponseInfo } from "../../types/user/userResponse.types";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    textAlign: "center",
  },
  link: {
    color: "#00BCD4",
    marginTop: "5px",
    cursor: "pointer",
  },
}));

type DriverIdentificationForgotPinPageProps = {
  assignModal: boolean,
  openAssignModal: () => void,
  closeAssignModal: () => void,
}

const DriverIdentificationForgotPinPage: React.FC<DriverIdentificationForgotPinPageProps> = ({
  assignModal,
  openAssignModal,
  closeAssignModal,
}) => {
  const [selectedDriver, setSelectedDriver] = useState<UserResponseInfo | null>(null);
  const vehicleId = useSelector(
    (state: RootState) => state.driverIdentification.vehicleId
  );
  const pathname = `driverIdentification/${vehicleId}`;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleName = useSelector(
    (state: RootState) => state.driverIdentification.vehicleName
  );
  const vehicleDriver = useSelector(
    (state: RootState) => state.driverIdentification.vehicleDriver
  );

  //Soc Test Vehicle Id
  // const vehicleId = "8c1b369a-65d5-44cb-f87f-08db72591a75";

  useEffect(() => {
    !vehicleName && dispatch(requestVehicleNameById(vehicleId));
  }, []);

  useEffect(() => {
    vehicleDriver && history.push(`/${pathname}/success`);
  }, [vehicleDriver]);

  const navigateBack = () => {
    history.push(`/${pathname}`);
  };

  const onHandleCancel = () => {
    closeAssignModal();
    setSelectedDriver(null);
  };

  const onHandleConfirm = () => {
    closeAssignModal();
    dispatch(
      requestAssignVehicleToDriverByDriverId({
        vehicleId,
        driverId: selectedDriver!.id,
      })
    );
  };

  return (
    <DriverIdentification>
      <Grid item xs={11} className={classes.contentContainer}>
        <Typography variant="subtitle2" style={{ color: "#B0BEC5" }}>
          <Translate
            id="driverIdentification.vehicleAssignment"
            data={{ vehicleName: vehicleName }}
          />
        </Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sm={5}
        md={4}
        lg={3}
        xl={2}
        className={classes.contentContainer}
      >
        <DriverSearchBox
          vehicleId={vehicleId}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
          openAssignModal={openAssignModal}
        />
      </Grid>
      <Grid item xs={11} className={classes.contentContainer}>
        <Typography
          onClick={navigateBack}
          variant="subtitle2"
          component={"span"}
          className={classes.link}
        >
           <Translate
            id="driverIdentification.mainPage" />
        </Typography>
      </Grid>

      <DriverIdentificationAssignModal
        assignModal={assignModal}
        onHandleCancel={onHandleCancel}
        onHandleConfirm={onHandleConfirm}
        vehicleName={vehicleName}
        selectedDriver={selectedDriver}
      />
    </DriverIdentification>
  );
};

export default DriverIdentificationForgotPinPage;
