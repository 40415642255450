import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Translate } from "react-localize-redux";
import TableTypes from "../../models/TableTypes";

import { usePagination } from "../../hooks/usePagination";

import { requestMisMatchTrips } from "../../actions/faultyTrips/actionMisMatchTrips";

import GenericTable from "../common/table/GenericTable";
import { PAGE_NAME } from "../common/container/Page";
import { RootState } from "../../store";

const DestinationMismatchTable = () => {
  const { page, rowsPerPage, changePage, changeRowPerPage } = usePagination();
  const misMatchTrips = useSelector((state: RootState) => state.misMatchTrips.entities);
  const total = useSelector((state: RootState) => state.misMatchTrips.total);
  const dispatch = useDispatch();

  useEffect(() => { 
    dispatch(requestMisMatchTrips({ page: page + 1, perPage: rowsPerPage }));
  }, [page, rowsPerPage]);

  const columnInformations = [
    {
      title: <Translate id="trip.table.driver" />,
      key: "mappedDriver",
    },
    {
      title: <Translate id="trip.table.vehicle" />,
      key: "mappedVehicle",
    },
    {
      title: <Translate id="trip.table.date" />,
      key: "mappedDate",
      type: TableTypes.date,
    },
    {
      title: <Translate id="trip.table.startAt" />,
      key: "startedAt",
      type: TableTypes.time,
    },
    {
      title: <Translate id="trip.table.finishAt" />,
      key: "finishedAt",
      type: TableTypes.time,
    },
    {
      title: <Translate id="trip.table.duration" />,
      key: "mappedDuration",
      noBr: true,
    },
    {
      title: <Translate id="trip.table.startAddress" />,
      key: "mappedStartAddress",
    },
    {
      title: <Translate id="trip.table.destinationAddress" />,
      key: "mappedDestinationAddress",
    },
    {
      title: <Translate id="trip.table.mileage" />,
      key: "mappedMileage",
      noBr: true,
    },
    {
      title: <Translate id="trip.table.tripType" />,
      key: "mappedTripType",
    },
  ];

  return (
    <GenericTable
      data={misMatchTrips}
      pageName={PAGE_NAME.faultyTrips}
      pageNavigation
      rowCount={total}
      page={page}
      rowsPerPage={rowsPerPage}
      columnInformations={columnInformations}
      handleChangePage={changePage}
      handleChangeRowsPerPage={changeRowPerPage}
    />
  );
};

export default DestinationMismatchTable;
