import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-localize-redux";

import Toolbar from "../../common/toolbar/Toolbar";
import ChargeStationSelect from "../../common/selects/specificSelects/ChargeStationSelect";
import ConnectorSelect from "../../common/selects/specificSelects/ConnectorSelect";

import { MESSAGE_TYPES } from "../../../types/qualityCharging/messageType";

import {
  requestStartConnectionToEvo,
  requestStopConnectionToEvo,
} from "../../../actions/qualityCharging/actionChargeStations";
import { updateFilterAPICall } from "../../../actions/actionFilter";

import { requestConnectorById } from "../../../actions/qualityCharging/actionConnectors";

import DateRangePicker from "../../common/selects/datePickers/DateRangePicker";

//Icons
import FontAwesomeIcon from "../../common/views/FontAwesomeIcon";

import moment from "moment";

import { fromToByPeriod } from "../../../managers/timeManager";
import { periods } from "../../../models/Period";

const QualityChargingToolbar = ({ setMessageType, messageType }) => {
  const dispatch = useDispatch();
  const [connectorNumber, setConnectorNumber] = useState(null);
  const [chargeStationId, setChargeStationId] = useState();
  const hasEvoConnectionStarted = useSelector(
    (state) => state.chargeStations.hasEvoConnectionStarted
  );
  const filter = useSelector((state) => state.filter);

  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateISO = new Date(currentDate).toISOString();
  const startTime = fromToByPeriod(periods.day.id).from;
  const endTime = fromToByPeriod(periods.day.id).to;

  // useEffect(() => {
  //     if (chargeStationId && connectorNumber !== null) {
  //       dispatch(
  //         requestConnectorById({
  //           chargeStationId,
  //           connectorNumber,
  //           from: filter.api.from
  //             ? new Date(filter.api.from).toISOString()
  //             : new Date(startTime).toISOString(),
  //           to: filter.api.to
  //             ? new Date(filter.api.to).toISOString()
  //             : new Date(endTime).toISOString(),
  //         })
  //       );
  //     }

  // }, [dispatch, chargeStationId, connectorNumber, filter.api.from, filter.api.to]);

  function handleDateChange(from, to) {
    dispatch(
      updateFilterAPICall({
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      })
    );
  }

  const actionButtons = !hasEvoConnectionStarted
    ? [
        {
          icon: (
            <FontAwesomeIcon minWidth={26} fontSize={17}>
              fa fa-play
            </FontAwesomeIcon>
          ),
          translateKey: "qualityCharging.startMqttService",
          onClick: () => dispatch(requestStartConnectionToEvo()),
        },
      ]
    : [
        {
          icon: (
            <FontAwesomeIcon minWidth={26} fontSize={17}>
              fa fa-stop-circle
            </FontAwesomeIcon>
          ),
          translateKey: "qualityCharging.stopMqttService",
          onClick: () => dispatch(requestStopConnectionToEvo()),
        },
      ];

  const toggles = [
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={
            messageType !== MESSAGE_TYPES.METERING_POINT
              ? "secondary"
              : undefined
          }
        >
          fa fa-calculator
        </FontAwesomeIcon>
      ),
      translateKey: "qualityCharging.messageType.meteringPoint",
      onClick: () => setMessageType(MESSAGE_TYPES.METERING_POINT),
      active: messageType === MESSAGE_TYPES.METERING_POINT,
    },
    {
      icon: (
        <FontAwesomeIcon
          fontSize={19}
          color={messageType !== MESSAGE_TYPES.STATUS ? "secondary" : undefined}
        >
          fa fa-industry
        </FontAwesomeIcon>
      ),
      translateKey: "qualityCharging.messageType.status",
      onClick: () => setMessageType(MESSAGE_TYPES.STATUS),
      active: messageType === MESSAGE_TYPES.STATUS,
    },
  ];

  const controls = [
    <ChargeStationSelect
      chargeStationId={chargeStationId}
      setConnectorNumber={setConnectorNumber}
      setChargeStationId={setChargeStationId}
    />,
    <ConnectorSelect
      connectorNumber={connectorNumber}
      setConnectorNumber={setConnectorNumber}
      startTime={startTime}
      endTime={endTime}
      from={filter.api.from}
      to={filter.api.to}
      chargeStationId={chargeStationId}
    />,
    <DateRangePicker
      onDateChange={handleDateChange}
      horizontalInputs
      startDate={
        filter.api.from
          ? new Date(filter.api.from).toISOString()
          : currentDateISO
      }
      endDate={
        filter.api.to ? new Date(filter.api.to).toISOString() : currentDateISO
      }
      disableFuture={false}
    />,
  ];

  return (
    <Toolbar
      title={<Translate id="qualityCharging.title" />}
      toggles={toggles}
      actionButtons={actionButtons}
      controls={controls}
    />
  );
};

export default QualityChargingToolbar;
