import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import TableTypes from "../../../models/TableTypes";
import { format } from "date-fns";
import { Tag } from "antd";

import { findPackageByID } from "../../../models/Package";

import { useContextMenu } from "../../../hooks/useModalStatus";

import GenericTableCell from "./GenericTableCell";
import GenericTablePagination from "./GenericTablePagination";
import LoadingIndicator from "../LoadingIndicator";
import FontAwesomeIcon from "../views/FontAwesomeIcon";

import { hasDriverLeaderPermission } from "../../../models/Role";
import { findGeoCodingById } from "../../../models/GeoCoding";
import { findEngineTypeById } from "../../../models/EngineType";
import { PAGE_NAME } from "../container/Page";
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: "auto",
  },
  noHover: {
    pointerEvents: 'none',
  },
  noData: {
    textAlign: "center"
  },
  bottomGap: {
    marginTop: theme.spacing(9),
  }
});

const orderDirection = {
  desc: "desc",
  asc: "asc",
};

const GenericTable = (props) => {
  const {
    anchorEl,
    open: openContextMenu,
    close: closeContextMenu,
  } = useContextMenu();
  const [order, setOrder] = useState(orderDirection.asc);
  const [orderBy, setOrderBy] = useState("startedAt");
  const [currentRow, setCurrentRow] = useState();
  const me = useSelector(state => state.users.me);

  const clientSort = false;

  const {
    classes,
    numSelected,
    onSelect,
    onSelectAllClick,
    actions,
    data,
    columnInformations,
    onEdit,
    onRowClick,
    showCheckboxes,
    showActions,
    typeIcons,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    rowCount,
    pageNavigation,
    hover,
    loading,
    disableContainer,
    settings,
    pageName,
    engineType,
  } = props;

  const isFuelVehicle = engineType === "Fuel"
  const engineTypeUnit = engineType === "Fuel" ? "L" : "kWh"

  const dataSortDirection = settings ? settings.orderBy : 1
  
  function handleRequestSort(event, property) {
    if (orderBy === property) {
      setOrder(
        order === orderDirection.asc ? orderDirection.desc : orderDirection.asc
      );
    }
    setOrderBy(property);
  }

  function sortTableByAttribute(array, order, orderBy) {
    return array.sort((a, b) => {
      const valueA = orderBy.split(".").reduce((o, i) => (o ? o[i] : o), a);
      const valueB = orderBy.split(".").reduce((o, i) => (o ? o[i] : o), b);
      return order === orderDirection.desc
        ? valueA > valueB
          ? 1
          : valueB > valueA
          ? -1
          : 0
        : valueA < valueB
        ? 1
        : valueB < valueA
        ? -1
        : 0;
    });
  }

  function defineAverageFuelBoxStyle(c) {
    const baseStyle = {
        border: '1px solid',
        paddingLeft: 7,
        paddingRight: 7,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 3,
        width: '80%',

    };

    if (c === "red") {
        baseStyle.border += ' red';
    } else {
        baseStyle.border += ' green';
    }
    return baseStyle;
}

function addZeroValues(number) {
  if(number){
    return number.toString().padStart(4, '0');
  } else {
    return <Translate id="driverIdentification.noPin" />
  }
}

  useEffect(() => {
    setOrder(dataSortDirection === 1 ? orderDirection.asc : orderDirection.desc);
}, [dataSortDirection]);

  function renderHeader() {
    if (clientSort)
      return columnInformations.map((information) => (
        <TableCell
          key={information.key}
          align="left"
          padding="default"
          sortDirection={orderBy === information.key ? order : false}
        >
          <Tooltip title="Sort" enterDelay={300}>
            <TableSortLabel
              active={orderBy === information.key}
              direction={order}
              onClick={(event) => handleRequestSort(event, information.key)}
            >
              {information.title}
            </TableSortLabel>
          </Tooltip>
        </TableCell>
      ));
    else
      return columnInformations.map((information) => (
        <TableCell key={`table-cell-${information.key}`} align="left" padding="default">
          {information.title}
        </TableCell>
      ));
  }

  const selectableRows = data.filter((d) => d && !d.lockedAt).length;
  const selectAllCheckbox = showCheckboxes ? (
    <Checkbox
      color="primary"
      indeterminate={numSelected > 0 && numSelected < selectableRows}
      checked={numSelected === selectableRows}
      onChange={onSelectAllClick}
    />
  ) : (
    <div />
  );

  const contextMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={closeContextMenu}
    >
      {actions
        .filter((a) => !a.comparator || a.comparator.isValid(currentRow))
        .map((action, i) => (
          <MenuItem
            key={`table-menuitem-${i}`}
            onClick={() => {
              closeContextMenu();
              action.event(currentRow);
            }}
          >
            {action.title}
          </MenuItem>
        ))}
    </Menu>
  );

  const handleRowClick = (row) => {
    onRowClick && hasDriverLeaderPermission(me, pageName) && onRowClick(row);           
  }

  const handleRowDoubleClick = (row) => {
    onEdit && hasDriverLeaderPermission(me, pageName) && onEdit(row);
  }

  if((!data || data.length === 0) && (pageName === PAGE_NAME.qualityCharging || pageName === PAGE_NAME.faultyTrips)) {
    return (
      <>
        {loading && <LoadingIndicator />}
        {!loading && (
          <Table size="small" className={hasDriverLeaderPermission(me, pageName) ? classes.bottomGap : classes.noHover}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">{selectAllCheckbox}</TableCell>
                {renderHeader()}
                {typeIcons && <TableCell />}
                {showActions && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
            <TableCell colSpan={8} className={classes.noData}>
              No Available Data
            </TableCell>
          </TableRow>
            </TableBody>
          </Table>
        )}
      </>
    );
  }

  if ((!data || data.length === 0)) {
    return <div />;
  }

  const table = (
    <>
      {loading && <LoadingIndicator />}
      {!loading && pageNavigation && (
        <GenericTablePagination
          rowCount={rowCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {!loading && (
        <Table size="small" className={!hasDriverLeaderPermission(me, pageName) && classes.noHover}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">{selectAllCheckbox}</TableCell>
              {renderHeader()}
              {typeIcons && <TableCell />}
              {showActions && <TableCell />}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortTableByAttribute(data, order, orderBy).map((row, i) => {
              const isLocked = row.lockedAt;
              return (
                <TableRow
                  key={row.id}
                  hover={hover}
                  onClick={() => {
                    //if (onRowClick) onRowClick(row);
                  }}
                  onDoubleClick={() => handleRowDoubleClick(row)}
                  style={{ height: "55px"}}
                  
                >
                  {showCheckboxes && !isLocked ? (
                    <TableCell
                      onClick={(e) => onSelect(row)}
                      padding="checkbox"
                    >
                      <Checkbox color="primary" checked={row.isSelected} />
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  {columnInformations.map((column, j) => {
                    var value = column.key
                      .split(".")
                      .reduce((o, i) => (o ? o[i] : o), row);
                    const isValueRed = column.key === 'averageFuelConsumption' && row.overConsumption;
                    const isGreen = column.key === 'averageFuelConsumption' && row.underConsumption;
                    const color = column.color;
                    let isColorValid =
                      (color && !color.comparator) ||
                      (color && color.comparator.isValid(row));
                    let printColor = isColorValid
                      ? color.value
                      : "rgba(0, 0, 0, 0.87)";
                    let noBr = column.noBr || false;
                    switch (column.type) {
                      case TableTypes.date:
                        value = value
                          ? format(new Date(value), "dd.MM.yyyy")
                          : "";
                        break;
                      case TableTypes.dateTime:
                        value = value
                          ? format(new Date(value), "dd.MM.yy H:mm")
                          : "";
                        break;
                      case TableTypes.time:
                        value = value ? format(new Date(value), "HH:mm") : "";
                        break;
                      case TableTypes.group:
                        value =
                          value &&
                          value.length > 0 &&
                          value.map((v) => (
                            <Tag
                              key={"tag#" + j + "#" + v?.name}
                              color={isColorValid ? printColor : "blue"}
                            >
                              {v?.name}
                            </Tag>
                          ));
                        break;
                        case TableTypes.package:
                        value =
                          value &&
                          value.length > 0 &&
                        value.map((v) => (
                            <Tag
                              key={"tag#" + j + "#" + findPackageByID(v)?.name}
                              color="volcano"
                            >
                              {findPackageByID(v)?.name}
                            </Tag>
                          ));
                        break;
                        case TableTypes.preferredGeolocationServiceProvider:
                        value = value
                        const geoCodingObj = findGeoCodingById(value);
                        value = geoCodingObj ? geoCodingObj.name : ''
                            break;
                        case TableTypes.fuelAtStart:
                          value = `${value}${isFuelVehicle ? engineTypeUnit : ""}`;
                        break;
                        case TableTypes.fuelAtFinish:
                          value = `${value}${isFuelVehicle ? engineTypeUnit : ""}`;
                        break;
                        case TableTypes.averageFuel:
                          value = <div style={ isValueRed ? defineAverageFuelBoxStyle("red") : isGreen ? defineAverageFuelBoxStyle("green") : {color: 'inheir'}}>{value} {engineTypeUnit}/100km</div> 
                            break;
                        case TableTypes.engineType:
                          value = value
                          const engineTypeObj = findEngineTypeById(value);
                          value = engineTypeObj ? engineTypeObj.name : 'Keine Angabe'
                            break;
                        case TableTypes.driverPin:
                            value = addZeroValues(value)
                        default:
                            break;
                    }
                    return (
                      <GenericTableCell
                        key={"tc#" + row.id + "#" + j}
                        color={printColor}
                        onClick={() => handleRowClick(row)}
                        disabled={isLocked}
                      >
                        {noBr ? <nobr>{value}</nobr> : value}
                      </GenericTableCell>
                    );
                  })}

                  {typeIcons && (
                    <TableCell>
                      {typeIcons.map((typeIcon, i) => {
                        if (typeIcon.comparator.isValid(row)) {
                          return (
                            <Tooltip
                              key={`table-tooltip-${i}`}
                              title={typeIcon.title}
                              aria-label={typeIcon.title}
                            >
                              {typeIcon.icon}
                            </Tooltip>
                          );
                        }
                      })}
                    </TableCell>
                  )}

                  {showActions &&
                    actions.filter(
                      (a) => !a.comparator || a.comparator.isValid(row)
                    ).length > 0 && (
                      <TableCell align="right">
                        {hasDriverLeaderPermission(me, pageName) && (<IconButton
                          onClick={(e) => {
                            setCurrentRow(row);
                            openContextMenu(e.currentTarget);
                          }}
                          aria-label="Edit"
                        >
                          <FontAwesomeIcon>far fa-ellipsis-v</FontAwesomeIcon>
                        </IconButton>)}
                      </TableCell>
                    )}
                  {!actions.filter(
                    (a) => !a.comparator || a.comparator.isValid(row)
                  ).length > 0 && <TableCell />}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {!loading && pageNavigation && (
        <GenericTablePagination
          rowCount={rowCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {contextMenu}
    </>
  );

  return (
    <>
      {!disableContainer && (
        <Paper classes={classNames.root}>
          <div className={classes.tableWrapper}>{table}</div>
        </Paper>
      )}
      {disableContainer && table}
    </>
  );
};

GenericTable.defaultProps = {
  showCheckboxes: false,
  showActions: false,
  actions: [],
  typeIcons: [],
  hover: true,
};

export default withStyles(styles, { withTheme: true })(GenericTable);
