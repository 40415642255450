import React, { useState } from "react";
import { Button, Modal } from "antd";

import { Translate } from "react-localize-redux";

import "./DriverIdentificationAssignModal.css";
import { UserResponseInfo } from "../../../types/user/userResponse.types";

type AssignModalProps = {
  assignModal: boolean;
  vehicleName: string;
  selectedDriver?: UserResponseInfo | null;
  driverName?: string;
  onHandleCancel: () => void;
  onHandleConfirm: () => void;
};

const DriverIdentificationAssignModal: React.FC<AssignModalProps> = ({
  assignModal,
  vehicleName,
  selectedDriver,
  driverName,
  onHandleCancel,
  onHandleConfirm,
}) => {
  return (
    <Modal
      title={
        <Translate id="driverIdentification.vehicleAssignmentModalTitle" />
      }
      centered
      open={assignModal}
      onOk={onHandleConfirm}
      onCancel={onHandleCancel}
      className="driver-identification-assign-modal"
      okText={<Translate id="driverIdentification.confirm" />}
    >
      {driverName ? (
        <p>
          <Translate
            id="driverIdentification.vehicleAssignmentModalText1"
            data={{ vehicleName: vehicleName, driverName: driverName }}
          />
        </p>
      ) : (
        <p>
          <Translate
            id="driverIdentification.vehicleAssignmentModalText2"
            data={{
              vehicleName: vehicleName,
              firstName: selectedDriver?.firstName,
              lastName: selectedDriver?.lastName,
            }}
          />
        </p>
      )}
    </Modal>
  );
};

export default DriverIdentificationAssignModal;
