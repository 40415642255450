import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";

import { Translate } from "react-localize-redux";

import {
  requestVehicleNameById,
  clearDriverName,
  requestDriverNameByPin,
  requestAssignVehicleToDriverByPin,
  setVehicleId
} from "../../actions/actionDriverIdentification";

import DriverIdentification from "./DriverIdentification";
import PinInput from "./PinInput/PinInput";
import DriverIdentificationConditionalLink from "./DriverIdentificationConditionalLink";
import DriverIdentificationAssignModal from "./DriverIdentificationAssignModal/DriverIdentificationAssignModal";

import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { RootState } from "../../store";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    textAlign: "center",
  },
}));

type DriverIdentificationPinAssignmentProps = {
  assignModal: boolean,
  openAssignModal: () => void,
  closeAssignModal: () => void,
}

type Params = {
  vehicleId: string,
}

const DriverIdentificationPinAssignment: React.FC<DriverIdentificationPinAssignmentProps> = ({
  assignModal,
  openAssignModal,
  closeAssignModal,
}) => {
  const pinLength = 4;
  const [pin, setPin] = useState(Array(pinLength).fill(""));
  const { vehicleId } = useParams<Params>();
  const pathname = `driverIdentification/${vehicleId}`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const vehicleName = useSelector(
    (state: RootState) => state.driverIdentification.vehicleName
  );
  const driverName = useSelector(
    (state: RootState) => state.driverIdentification.driverName
  );
  const vehicleDriver = useSelector(
    (state: RootState) => state.driverIdentification.vehicleDriver
  );

  useEffect(() => {
    !vehicleName && dispatch(requestVehicleNameById(vehicleId));

    dispatch(setVehicleId(vehicleId));
  }, []);

  useEffect(() => {
    vehicleDriver && history.push(`/${pathname}/success`);
  }, [vehicleDriver]);

  useEffect(() => {
    if (pin[pinLength - 1]) {
      const concatenatedPin = pin.join("");
      dispatch(requestDriverNameByPin({ vehicleId, pin: concatenatedPin }));
      openAssignModal();
    }

    return () => closeAssignModal();
  }, [pin]);

  const onHandleCancel = () => {
    setPin(Array(pinLength).fill(""));
    setCurrentIndex(0);
    dispatch(clearDriverName());
    closeAssignModal();
  };

  const onHandleConfirm = () => {
    closeAssignModal();
    const concatenatedPin = pin.join("");
    dispatch(requestAssignVehicleToDriverByPin({vehicleId, pin: concatenatedPin}));
  }

  return (
    <DriverIdentification>
      <Grid item xs={10} className={classes.contentContainer}>
        {vehicleName && (
          <Typography variant="subtitle2" style={{ color: "#B0BEC5" }}>
            <Translate id="driverIdentification.VehicleConfirmationMessage" data={{vehicleName: vehicleName}} />
          </Typography>
        )}
      </Grid>
      <Grid item xs={10} className={classes.contentContainer}>
        <PinInput length={pinLength} pin={pin} setPin={setPin} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
      </Grid>
      <Grid item xs={10} className={classes.contentContainer}>
        <DriverIdentificationConditionalLink pin={pin} pinLength={pinLength} pathname={pathname} />
      </Grid>
      <DriverIdentificationAssignModal
        assignModal={assignModal}
        vehicleName={vehicleName}
        driverName={driverName}
        onHandleCancel={onHandleCancel}
        onHandleConfirm={onHandleConfirm}
      />
    </DriverIdentification>
  );
};

export default DriverIdentificationPinAssignment;
