import { Box, Grid, Typography } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { driverIdentificationClearError } from "../../actions/actionDriverIdentification";

import DriverIdentification from "./DriverIdentification";

import { makeStyles } from "@material-ui/core";

import { Translate } from "react-localize-redux";

import errorImg from "../../assets/error.png";

import { Theme } from "@material-ui/core";
import { RootState } from "../../store";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    textAlign: "center",
  },

  gap: {
    height: "10vh",
  },

  errorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  errorMessage: {
    fontWeight: 500,
    color: "#F0F0F0",

    [theme.breakpoints.up("xs")]: {
      margin: "25px 0 10px 0",
      fontSize: "clamp(1rem, 1.3vw + 1rem, 2.5rem)",
    },

    [theme.breakpoints.up("md")]: {
      margin: "30px 0 20px 0",
      fontSize: "clamp(1rem, 0.5vw + 1rem, 2rem)",
    },
  },

  link: {
    color: "#00BCD4",
    marginTop: "5px",
    cursor: "pointer",
  },

  errorImg: {
    width: "clamp(6.5rem, 1.5vw + 6.5rem, 11rem)",
  },
}));

const DriverIdentificationErrorPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleId = useSelector(
    (state: RootState) => state.driverIdentification.vehicleId
  );
  const error = useSelector((state: RootState) => state.driverIdentification.error);
  const pathname = `driverIdentification/${vehicleId}`;

  const navigateBack = async () => {
    dispatch(driverIdentificationClearError());
    history.push(`/${pathname}`);
  };

  return (
    <DriverIdentification>
      <Grid item xs={10} className={classes.gap}></Grid>
      <Grid item xs={10} className={classes.contentContainer}>
        <Box className={classes.errorContainer}>
          <img
            src={errorImg}
            alt="success sign"
            className={classes.errorImg}
          />
          <Typography className={classes.errorMessage}>
            {error?.message}
          </Typography>
        </Box>
        <Typography
          onClick={navigateBack}
          variant="subtitle2"
          component={"span"}
          className={classes.link}
        >
          <Translate id="driverIdentification.tryAgain" />
        </Typography>
      </Grid>
    </DriverIdentification>
  );
};

export default DriverIdentificationErrorPage;
